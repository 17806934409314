import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
}

export function PageTitle(props: Props) {
  return (
    <Helmet>
      <title>{props.title ? `${props.title} | Resolve` : 'Resolve'}</title>
    </Helmet>
  );
}
