import React from 'react';
import styled from 'styled-components';
import { withMargin } from '../styled/with-margin';
import { Link } from 'react-router-dom';

const Wrapper = styled.h1<{ margin?: string }>`
  position: relative;
  ${withMargin};
`;

const HomeLink = styled(Link)`
  color: #6937f5;
`;

const LogoText = styled.span`
  font-size: 6px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

const LogoImage = styled.svg<{ width: number }>`
  width: ${(p) => p.width}px;
`;

interface Props {
  margin?: string;
  width?: number;
}

export function Logo({ margin, width = 160 }: Props) {
  return (
    <Wrapper margin={margin}>
      <HomeLink to="/">
        <LogoText className="logo-text">resolve</LogoText>
        <LogoImage
          aria-hidden
          className="logo-image"
          viewBox="0 0 305 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width={width}
        >
          <path
            d="M0 65.2879H13.5307V42.4572C13.5307 33.6008 18.2487 29.4174 25.9932 29.4174C27.373 29.4174 28.8418 29.5509 30.4441 29.7734L30.6222 16.6446C29.2869 16.4221 28.1742 16.3331 26.8834 16.3331C20.8747 16.3331 16.0232 18.3358 13.5307 25.6345V16.8227H0V65.2879Z"
            fill="currentColor"
          />
          <path
            d="M80.7078 38.5853C80.7078 24.8779 72.3847 15.9326 58.3198 15.9326C43.7209 15.9326 34.5075 25.679 34.5075 41.3446C34.5075 56.4761 43.0533 66 58.0973 66C68.9575 66 76.613 60.971 79.9512 53.4053L67.5332 50.0674C66.1979 52.6487 63.5719 55.0964 58.4088 55.0964C51.866 55.0964 48.5279 50.9575 48.2163 45.5725H80.7078V38.5853ZM58.2753 26.7916C63.4384 26.7916 66.8655 29.7289 67.1326 36.4046H48.2608C48.8839 30.619 52.4001 26.7916 58.2753 26.7916Z"
            fill="currentColor"
          />
          <path
            d="M128.491 29.3729C126.355 21.5401 119.1 15.9326 106.86 15.9326C94.0859 15.9326 85.9408 22.0297 85.9408 32.0432C85.9408 43.5253 96.6674 44.9494 105.347 46.1065C111.088 46.8631 115.94 47.5307 115.94 51.0465C115.94 53.7168 113.136 55.5415 108.64 55.5415C103.21 55.5415 99.6495 53.0047 98.4033 49.1773L85.5847 52.1146C88.2998 60.4815 97.068 66 108.462 66C121.058 66 129.426 59.1908 129.426 49.1773C129.426 37.8287 118.788 36.7606 110.198 35.7815C104.323 35.114 99.3825 34.5354 99.3825 30.7525C99.3825 28.1713 101.697 26.3466 106.415 26.3466C111.89 26.3466 114.649 28.7053 115.762 32.2657L128.491 29.3729Z"
            fill="currentColor"
          />
          <path
            d="M157.893 15.9326C143.338 15.9326 134.169 25.679 134.169 40.9885C134.169 56.2535 143.338 66 157.893 66C172.447 66 181.616 56.2535 181.616 40.9885C181.616 25.679 172.447 15.9326 157.893 15.9326ZM157.893 27.1032C164.391 27.1032 168.085 31.9096 168.085 40.9885C168.085 50.0229 164.391 54.8294 157.893 54.8294C151.394 54.8294 147.7 50.0229 147.7 40.9885C147.7 31.9096 151.394 27.1032 157.893 27.1032Z"
            fill="currentColor"
          />
          <path d="M188.462 65.2879H201.993V0H188.462V65.2879Z" fill="currentColor" />
          <path
            d="M232.95 48.9993L221.378 16.8227H206.868L225.74 65.2879H240.027L258.899 16.8227H244.389L232.95 48.9993Z"
            fill="currentColor"
          />
          <path
            d="M305 38.5853C305 24.8779 296.677 15.9326 282.612 15.9326C268.013 15.9326 258.8 25.679 258.8 41.3446C258.8 56.4761 267.345 66 282.389 66C293.25 66 300.905 60.971 304.243 53.4053L291.825 50.0674C290.49 52.6487 287.864 55.0964 282.701 55.0964C276.158 55.0964 272.82 50.9575 272.508 45.5725H305V38.5853ZM282.567 26.7916C287.731 26.7916 291.158 29.7289 291.425 36.4046H272.553C273.176 30.619 276.692 26.7916 282.567 26.7916Z"
            fill="currentColor"
          />
        </LogoImage>
      </HomeLink>
    </Wrapper>
  );
}
