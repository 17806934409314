import React from 'react';
import styled from 'styled-components';
import { withMargin, WithMarginProp } from '../styled/with-margin';

interface Props {
  label: string;
  href: string;
  target?: string;
  margin?: string;
}

const ButtonElement = styled.a<WithMarginProp>`
  display: table;
  border: none;
  border-radius: 4px;
  padding: 16px 24px 15px 24px;
  background: #6937f5;
  font-family: inherit;
  font-variation-settings: 'wght' 650;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  color: #fff;
  position: relative;
  width: auto;
  text-decoration: none;

  &:hover {
    background: #4923b7;
  }

  &:active {
    transform: translate3d(0, 1px, 0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(131, 192, 253, 1);
  }

  ${withMargin};
`;

export function LinkButton({ label, href, target, margin }: Props) {
  return (
    <ButtonElement href={href} target={target} margin={margin}>
      {label}
    </ButtonElement>
  );
}
