import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ChatBubble, Props as BubbleProps } from './ChatBubble';

var popAudioLeft = new Audio('/audio/pop-left.mp3');
var popAudioRight = new Audio('/audio/pop-right.mp3');

function randomMessage() {
  let messages = [
    'Hi where’s my order?',
    "It's been 2 days, can you reply?",
    'Do you do click & collect?',
    'My pizza was cold',
    'one of the items I ordered arrived broken :( ',
    'Can you change the address on my account?',
    'I forgot my password',
    'can you change my payment details',
    'I need to change the name on my account',
    'can you help please?',
    'Can I return my order?',
    'I called yesterday but haven’t heard back',
    'can you delete my account please?',
    'unsubscribe me',
    'I want to change my payment plan',
    'I’d like a refund please',
    'Can you please stop emailing me',
    'I get an error when using the app',
    'I was charged twice can you refund',
    "my tracking code doesn't seem to work",
    'halp',
    'anyone there?',
    "I can't reset my password not sure why",
    'how do I cancel my account',
    'my last order never arrived',
    'Can you call me back please?',
    'How do I cancel my subscription',
    'I think I got someone elses food?',
    'Do you have my prescription?',
    'I never received my last order?',
  ];

  return messages[Math.round(Math.random() * (messages.length - 1))];
}

const Matte = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: help;
`;

const MIN_WIDTH = 800;

export function ChatBubbles() {
  const [count, setCount] = useState(0);
  const [bubbles, setBubbles] = useState<BubbleProps[]>([]);
  const [showBubbles, setShowBubbles] = useState(window.innerWidth > MIN_WIDTH);

  const addMessage = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      if (!showBubbles) {
        return;
      }

      setBubbles((state) => [
        ...state,
        {
          x,
          y,
          message: randomMessage(),
          id: count,
          onAnimationCompleted: () => {
            setBubbles((state) => [...state.filter((b) => b.id !== count)]);
          },
          origin: count % 2 === 0 ? 'right' : 'left',
        },
      ]);
      setCount((count) => count + 1);
    },
    [count, showBubbles]
  );

  function onClick(event: React.MouseEvent<HTMLDivElement>) {
    const x = event.pageX;
    const y = event.pageY;

    addMessage({ x, y });

    if (bubbles.length % 2 === 0) {
      popAudioLeft.play();
    } else {
      popAudioRight.play();
    }
  }

  useEffect(() => {
    if (bubbles.length !== 0 || !showBubbles) {
      return;
    }

    const timeout = setTimeout(() => {
      const content = document.querySelector('#content');

      if (!content) {
        console.warn('failed to find any element matching "#content"');
        return;
      }

      const contentRect = content.getBoundingClientRect();

      let y = Math.random() * window.innerHeight * 0.5 + window.innerHeight * 0.4;
      let x = Math.random() * contentRect.x;

      if (count % 2 === 0) {
        x += contentRect.width + contentRect.x;
      }

      addMessage({ x, y });
    }, Math.random() * 1000 + 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [addMessage, bubbles.length, count, showBubbles]);

  useEffect(() => {
    function onResize() {
      setShowBubbles(window.innerWidth > MIN_WIDTH);
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setShowBubbles]);

  return (
    <>
      <Matte onClick={onClick} />
      {bubbles.map((bubbleProps) => (
        <ChatBubble {...bubbleProps} key={bubbleProps.id} />
      ))}
    </>
  );
}
