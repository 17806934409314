import React from 'react';
import styled, { css } from 'styled-components';
import { withMargin } from '../styled/with-margin';

type Size = 'xl' | 'l' | 'm' | 's';
type Align = 'left' | 'center' | 'right';

interface Props {
  size: Size;
  level: 'h1' | 'h2' | 'h3';
  children: string;
  margin?: string;
  align?: Align;
}

const HeadingElement = styled.h1<{ size: Size; align: Align; margin?: string }>`
  font-weight: normal;
  font-variation-settings: 'wght' 800;
  margin: 0;
  text-align: ${(p) => p.align};
  line-height: 1.1;

  ${(p) => {
    if (p.size === 'xl') {
      return css`
        font-variation-settings: 'wght' 1000;
        font-size: 48px;
      `;
    }

    if (p.size === 'l') {
      return css`
        font-size: 30px;
      `;
    }

    if (p.size === 'm') {
      return css`
        font-size: 24px;
      `;
    }

    if (p.size === 's') {
      return css`
        font-size: 20px;
      `;
    }
  }}
  ${withMargin};
`;

export function Heading({ level, size = 'm', align = 'left', children, margin }: Props) {
  return (
    <HeadingElement as={level} size={size} align={align} margin={margin}>
      {children}
    </HeadingElement>
  );
}
