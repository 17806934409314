import './styled/fonts.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import { RedirectNotice } from './components/RedirectNotice';

const GlobalStyles = createGlobalStyle`

  *,
  *::before,
  &::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0

  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    background: #f9f2e6;
    color: #324260;

    /* Typography settings */
    font-family: 'SpeziaNormal-Upright', -apple-system, sans-serif;
    font-variation-settings: 'wght' 400, 'wdth' 050;
    line-height: 1.4;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    font-feature-settings: 'liga' on;
    font-feature-settings: 'dlig' on;
    font-feature-settings: 'tnum' on;
    font-feature-settings: 'onum' on;
    display: flex;
    flex-direction: column;
  }

  #root {
    position: relative;
  }
`;

function App() {
  return (
    <React.StrictMode>
      <GlobalStyles />
      <BrowserRouter>
        <GoogleAnalytics />
        <RedirectNotice />
      </BrowserRouter>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
