import React from 'react';
import styled, { css } from 'styled-components';
import { withMargin } from '../styled/with-margin';

type Size = 'inherit' | 'l' | 'm' | 's' | 'xs';
type Color = 'inherit' | 'light-grey' | 'dark-grey' | 'red';
type Align = 'left' | 'center' | 'right';

interface Props {
  children: React.ReactNode;
  size?: Size;
  color?: Color;
  isInline?: boolean;
  isBold?: boolean;
  margin?: string;
  align?: Align;
  className?: string;
}

const TextElement = styled.p<Props>`
  font-family: 'SpeziaNormal-Upright', -apple-system, sans-serif;
  font-weight: normal;
  font-variation-settings: 'wght' ${(p) => (p.isBold ? 700 : 400)};
  line-height: 1.6;
  color: inherit;
  display: ${(p) => (p.isInline ? 'inline' : 'block')};
  margin: 0;
  text-align: ${(p) => p.align || 'left'};

  /* Size */
  ${(p) => {
    if (p.size === 'l') {
      return css`
        font-size: 20px;
      `;
    }

    if (p.size === 'm') {
      return css`
        font-size: 18px;
      `;
    }

    if (p.size === 's') {
      return css`
        font-size: 16px;
      `;
    }

    if (p.size === 'xs') {
      return css`
        font-size: 14px;
      `;
    }
  }}

  /* Color */
  ${(p) => {
    if (p.color === 'dark-grey') {
      return css`
        color: #324260;
      `;
    }

    if (p.color === 'light-grey') {
      return css`
        color: #817e88;
      `;
    }
  }}

  ${withMargin};
`;

export function Text({
  size = 'inherit',
  color = 'inherit',
  isInline = false,
  isBold = false,
  align = 'left',
  className,
  children,
  margin,
}: Props) {
  return (
    <TextElement
      as={isInline ? (isBold ? 'strong' : 'span') : 'p'}
      size={size}
      color={color}
      isInline={isInline}
      isBold={isBold}
      margin={margin}
      align={align}
      className={className}
    >
      {children}
    </TextElement>
  );
}
