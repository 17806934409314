import React from 'react';
import { Heading } from './Heading';
import { Layout } from './Layout';
import { Text } from './Text';
import { PageTitle } from './PageTitle';
import { LinkButton } from './LinkButton';
import styled from 'styled-components';

interface Props {
  href?: string;
}

const PageContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContent = styled.div`
  max-width: 480px;
  text-align: center; ;
`;

const SubtleLink = styled.a`
  color: inherit;
  text-decoration: underline;
`;

export function RedirectNotice() {
  return (
    <Layout withChatBubbles={true}>
      <PageTitle />
      <PageContent>
        <TextContent>
          <Text size="s" color="light-grey" margin="0 0 s">
            We've moved to a new home!
          </Text>
          <Heading size="l" level="h1" margin="0 0 s">
            We're now called Plain.
          </Heading>
          <Text size="m" margin="0 0 l">
            Don't worry, it's just a new name, our mission hasn't changed{' '}
            <span role="img" aria-label="wink">
              😉
            </span>
          </Text>
          <LinkButton label="Ok, let's see it then" href="https://www.plain.co" margin="0 0 xl" />

          <Text size="xs" color="light-grey" margin="0 0 xs">
            P.S. If you got here from a link in the wild it would be really helpful if you could{' '}
            <SubtleLink href="mailto:mattandsimon@plain.co">let us know</SubtleLink> so we can fix
            it.
          </Text>
        </TextContent>
      </PageContent>
    </Layout>
  );
}
