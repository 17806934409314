import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { tokens } from '../styled/tokens';
import { ChatBubbles } from './ChatBubbles';
import { Logo } from './Logo';

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ extendedLayout?: boolean }>`
  flex-grow: 1;
  width: 100%;
  max-width: ${(p) => (p.extendedLayout ? tokens.extendedPageWidth : tokens.pageWidth)};
  padding: 0 ${tokens.edgePadding};
  margin: 0 auto;
  padding: 52px 24px 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${tokens.pageWidth}) {
    padding-top: 24px;
  }
`;

const NavigationBar = styled.nav`
  display: block;
  border-bottom: 1px solid #ded7c9;
  display: flex;
  align-items: center;
`;

const NavigationBarContent = styled.div`
  width: ${tokens.pageWidth};
  margin: 0 auto;
  padding: 8px ${tokens.edgePadding} 6px;
`;

interface Props {
  children: React.ReactNode;
  withNavigationBar?: boolean;
  withChatBubbles?: boolean;
  extendedLayout?: boolean;
}

export function Layout(props: Props) {
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Wrapper id="content">
        {props.withNavigationBar && (
          <NavigationBar>
            <NavigationBarContent>
              <Logo width={90} />
            </NavigationBarContent>
          </NavigationBar>
        )}
        <Content extendedLayout={props.extendedLayout}>{props.children}</Content>
        {props.withChatBubbles && <ChatBubbles />}
      </Wrapper>
    </>
  );
}
