import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function GoogleAnalytics() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!window.gtag) {
      return;
    }

    window.gtag('config', 'UA-176953328-1', { page_path: pathname });
  }, [pathname, search]);

  return null;
}
