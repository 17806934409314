import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import leftTail from '../images/bubble-tail-left.svg';
import rightTail from '../images/bubble-tail-right.svg';

type Origin = 'left' | 'right';

export interface Props {
  x: number;
  y: number;
  message: string;
  id: number;
  origin: Origin;
  onAnimationCompleted: () => void;
}

const slideAndFade = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -200px, 0);
  }
`;

const bubblePop = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 1);
    color: #f9f2e6;
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const FixedAnchor = styled.div<{ x: number; y: number }>`
  width: 1px;
  height: 1px;
  position: fixed;
  pointer-events: none;
  top: ${(p) => p.y}px;
  left: ${(p) => p.x}px;
  z-index: 999;
`;

const RelativeAnchor = styled.div<{ animationDelayMs: number }>`
  width: 1px;
  height: 1px;
  position: relative;
  animation: ${slideAndFade} cubic-bezier(0.85, 0, 0.15, 1) 1s;
  animation-fill-mode: forwards;
  animation-delay: ${(p) => p.animationDelayMs}ms;
`;

const BubbleContent = styled.div<{ origin: Origin }>`
  border-radius: 20px;
  animation: ${bubblePop} cubic-bezier(0.68, -0.6, 0.32, 1.6) 300ms;
  animation-fill-mode: forwards;
  padding: 9px 16px 8px 16px;
  position: relative;
  color: #555;

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }

  ${(p) => {
    if (p.origin === 'left') {
      return css`
        background: #606681;
        position: absolute;
        left: 15px;
        bottom: 5px;
        transform-origin: bottom left;
      `;
    }

    if (p.origin === 'right') {
      return css`
        background: #373b50;
        position: absolute;
        right: 15px;
        bottom: 5px;
        transform-origin: bottom right;
      `;
    }
  }}

  ${(p) => {
    if (p.origin === 'left') {
      return css`
        &::before {
          left: -8px;
          bottom: -2px;
          background-image: url(${leftTail});
        }
      `;
    }

    if (p.origin === 'right') {
      return css`
        &::before {
          right: -8px;
          bottom: -2px;
          background-image: url(${rightTail});
        }
      `;
    }
  }}
`;

const BubbleText = styled.span`
  white-space: pre;
  font-size: 16px;
  line-height: 1;
  color: #fff;
`;

export function ChatBubble(props: Props) {
  return (
    <FixedAnchor x={props.x} y={props.y}>
      <RelativeAnchor
        animationDelayMs={props.message.length * 65}
        onAnimationEnd={(event) => {
          // Since some children also have animations we need this check to
          // only fire once
          if (event.currentTarget === event.target) {
            props.onAnimationCompleted();
          }
        }}
      >
        <BubbleContent origin={props.origin}>
          <BubbleText>{props.message}</BubbleText>
        </BubbleContent>
      </RelativeAnchor>
    </FixedAnchor>
  );
}
